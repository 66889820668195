export enum ActionType {
    TOGGLE_NAV = 'TOGGLE_NAV',
    CLOSE = 'CLOSE',
    OPEN = 'OPEN',
    GET_FORECAST = 'GET_FORECAST',
    IN = 'IN',
    OUT = 'OUT'
}

interface actionToggle {
    type: ActionType.TOGGLE_NAV
}
interface actionClose {
    type: ActionType.CLOSE
}
interface actionOpen {
    type: ActionType.OPEN
}

type Action = actionToggle | actionClose | actionOpen

const openNavReducer = (state: boolean = false, action: Action) => {
    switch(action.type) {
        case ActionType.TOGGLE_NAV:
            return !state;
        case ActionType.CLOSE:
            return false;
        case ActionType.OPEN:
            return true;
        default:
            return state
    }
}

export default openNavReducer