import React from 'react'
// Style
import styled from 'styled-components'
// Animation
import { motion } from 'framer-motion'
import { pageAnimation } from '../animations'
// Parallax


const Home: React.FC = () => {

 

  return (
    <article>
      <motion.div className="content"
        variants={pageAnimation}
        initial="hidden"
        animate="show"
        exit="exit"
      >

          <StyleInfo className='info'>
            <h4>Dňa 29.10.2022 to jest v sobotu sa možno bude konať polietanie s grilovaním, pre bližšie informacie mi zavolajte.</h4>
            <p>S pozdravom <br />predseda Juraj Kubičár</p>

          </StyleInfo>

        <StyleIframe>

          <h3>Polietanie 19.6.2022</h3>

          <iframe width="612" height="300" src="https://www.youtube.com/embed/N0BxT-vdeb0" title="Zdieľať" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </StyleIframe>

        <StyleSection>
          <p>
            Sme občianske združenie aktívnych leteckých modelárov. Nie sme uzatvorená skupina, ale radi medzi sebou privítame aj ďalších modelárov, ktorým vieme a sme ochotní pomôcť dostať sa na našu úroveň. Na našom letisku nás nájdeš prevažne cez víkendy v dopoludňajších hodinách ak je dobré počasie.
          </p>
        </StyleSection>

      </motion.div>
    </article>
  )
}
const StyleInfo = styled.div`
max-width: 500px;
  margin: 3em auto;
  padding: 1em;
  background: #f71d1d;
  //color: white;
  border-radius: 1em;
  box-shadow: 10px 10px 20px black;
  display: none;
`

const StyleSection = styled(motion.section)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2em;

  p {
    font-size: 1.1em;
    max-width: 20em;
    padding: 2em;
    border-radius: .4em;
    line-height: 1.8em;
    font-style: italic;
    transition: all .5s ease-in-out;
    &:hover {
      box-shadow: 1px 1px 5px 10px #000;
      box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    }
  }
`
const StyleIframe = styled.section`
  width: 100%;
  iframe {
    width: min(93vw, 512px);
    height: min(53vw, 290px);
    
  }
`

export default Home
