import React from 'react'
// style 
import styled from 'styled-components'
import Partner from './Partner'
import logoVL from '../assets/image/ERB LOMNICA FAR.png'
import logoTatramat from '../assets/image/logotm.png'
import { theme } from './Theme'

const Footer: React.FC = () => {
  return (
    <StyleFooter>
        <div className="content">

            <div className="pred-footer">
                <Partner img={logoVL} partner='Veľká Lomnica' href='https://www.velkalomnica.sk/' bossclas='logo' />

                <p className='moto' >Modelárstvo a lietanie <br /> je náš život.</p>

                <Partner img={logoTatramat} partner='TATRAMAT a.s.' href='http://tatramatas.sk/' clas='logo-tatramat' bossclas='logo' />
                
            </div>

        </div>
            <p className='footer' >© {new Date().getFullYear()} Všetky práva vyhradené.</p>
    </StyleFooter>
  )
}
const StyleFooter = styled.footer`
    font-size: 0.85em;
    //background: #EEEEEE;
    //background: #303030;
    background: ${theme.backgroundGradient};
    color: #fff;
    padding-top: 1em;
    border-radius: 4em 0 0 0;

    .pred-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .moto {
            font-style: italic;
        }
        @media (max-width: 1024px) {
            justify-content: space-around;
        }
        @media (max-width: 600px) {
            flex-direction: row;
            flex-wrap: wrap;
            .moto {
                width: 100%;
                order: 1;
                
            }
            .logo {
                order: 2;
            }
            
        }
    }
    .logo-tatramat {
        width: 8em;
    }
    .footer {
        margin: 0em;
        padding: 1.5em 0;
        margin-top: 1em;
        //background: rgba(0, 0, 0, 0.3);
    }
`

export default Footer