import React from 'react'
import styled from 'styled-components'
import { ForecastHour } from '../../App'
import { motion } from 'framer-motion'
import { forecastAnime, animChildren } from '../../animations'
import { mixin } from '../Theme'
import AutoClose from './AutoClose'

interface IProps {
    forecast: ForecastHour[] | null
    setOpenWind: (value: boolean) => void
}

const TestWind: React.FC<IProps> = ({ forecast, setOpenWind }) => {
    
    //* filter items only between 6h - 20h
    const filterWeather = forecast?.filter((item) => item.hour > 5)
    const filterWeather2 = filterWeather?.filter((item) => item.hour < 21)

    /**
     * 
     * @param hour 
     * @returns if number is < 10 create decimal string
     */
    const digital = (hour: number) => {
        let newHour = ''
      if(hour === 0) {
          newHour = `24`
        } else if (hour < 10) {
          newHour = `0${hour}`
      } else {
        newHour = `${hour}`
    }
      
      return newHour
    }

  return (
    <StyleWind variants={forecastAnime} initial="hidden" animate="show" exit="exit" >
        <p>Rýcholosť vetra <strong>Veľká Lomnica</strong> [m/s]</p>
        <div className="grid">
            <div className="row"><span className='l'>5</span><span className='r'>5</span></div>
            <div className="row"><span className='l'>4</span><span className='r'>4</span></div>
            <div className="row"><span className='l'>3</span><span className='r'>3</span></div>
            <div className="row"><span className='l'>2</span><span className='r'>2</span></div>
            <div className="row"><span className='l'>1</span><span className='r'>1</span></div>
            <div className="row"><span className='l'>0</span><span className='r'>0</span></div>
        </div>

        {filterWeather?.length === 0 && (
            <AutoClose setOpenWind={setOpenWind} />
        )}

        {filterWeather2?.map((one, index) => (
            <div 
                //variants={animChildren}
                title={`${one.wind}m/s`}   
                style={{
                    width: `calc(100% / ${filterWeather2.length})`,
                    borderRight: one.hour === 20 ? '2px solid black' : 'none'
                }}
                className='column' 
                key={index}
            >
                <motion.div 
                    initial={{height: 0}} 
                    animate={{height: one.wind * 12.8, transition: {duration: 0.75, delay: 0.02 * index + 1, ease: 'easeOut'}}}
                    className="wind"
                    style={{
                        background: one.wind <= 0.5 ? '#019BFA' : 
                                    one.wind <= 1 ? '#009BFB' :
                                    one.wind <= 1.5 ? '#86D6C3' :
                                    one.wind <= 2 ? '#04E203' :
                                    one.wind <= 2.5 ? '#68FE35' :
                                    one.wind <= 3 ? '#04E203' :
                                    one.wind <= 3.5 ? '#CCFD32' :
                                    one.wind <= 4 ? '#E2FE9A' :
                                    one.wind <= 4.5 ? '#FEFF99' :
                                    one.wind <= 5 ? '#FBDD57' :
                                    one.wind <= 5.5 ? '#FFC402' :
                                    one.wind <= 6 ? '#FF9966' :
                                    one.wind <= 6.5 ? '#FF6600' : '#CD3301',
                        height: (one.wind * 12.8)
                    }}
                >

                </motion.div>
                <div className="hour">
                    <span className='hour-number'>{digital(one.hour)}</span>
                </div>
                
            </div>
        ))}
    </StyleWind>
  )
}
const StyleWind = styled(motion.div)`
    position: absolute;
    right: 1.5em;
    bottom: .5em;
    width: 30em;
    height: 6em;
    //background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
    box-shadow: 0px -12px 0px 12px rgba(255,255,255,0.5);
    border: 1px solid black;
    display: flex;
    align-items: flex-end;
    //z-index: 20;

    @media (max-width: 830px) {
        max-width: 95%;
        right: .8em;
        bottom: 0em;
    }
    @media (max-width: 560px) {
        max-width: 94%;
    }
    @media (max-width: 440px) {
        max-width: 92%;
    }

    p {
        font-size: .8rem;
        position: absolute;
        top: -1rem;
        left: 50%;
        transform: translate(-50%);
        margin: 0;
        @media (max-width: 500px) {
            top: -1.1rem;
            width: ${mixin.em(240, 14.4)};
        }
    }

    .grid {
        position: absolute;
        width: 100%;
        //height: 100%;
        top: 0;
        left: 0;
        bottom: 1rem;
        
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        pointer-events: none;

        .row {
            width: 100%;
            height: 12px;
            border-top: 1px dashed #000;
            text-align: left;
            font-size: .7rem;
            padding-left: 0.1rem;
            display: flex;
            justify-content: space-between;
            .l {
                position: relative;
                right: 1em;
                bottom: -0.4em;
            }
            .r {
                position: relative;
                left: .8em;
                bottom: -0.4em;
            }
        }
    }

    .column {
        //width: calc(100% / 48);
        border-top: 1px solid black;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        //padding: 0 1px;
        .wind {
            border: 2px 2px 0 0;
            margin: 0 2px;
            @media (max-width: 830px) {
                margin: 0 1px;
            }
            @media (max-width: 500px) {
                margin-right: 1px;
            }
        }
        .hour {
            background: rgba(255, 255, 255, 0.9);
            height: 1rem;
            position: relative;
            .hour-number {
                font-size: .7rem;
                color: #000;
                @media (max-width: 750px) {
                    font-size: .6rem;
                    //height: .8rem;
                }
                @media (max-width: 600px) {
                    font-size: .5rem;
                }
                @media (max-width: 500px) {
                    font-size: .4em;
                    writing-mode: vertical-rl;
                    text-orientation: upright;      
                }
            }
        }
    }
`

export default TestWind