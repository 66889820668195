export const pageAnimation = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            duration: 0.5,
            when: "beforeChildren",
            staggerChildren: 0.1,
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.5
        }
    }
}

// show submenu
export const showMenuAnim = {
    hidden: {
        opacity: 0,
        //y: '-100%',
        scale: 0
    },
    show: {
        opacity: 1,
        //y: '0%',
        scale: 1,
        transition: {
            duration: 0.25
        }
    },
    exit: {
        opacity: 0,
        //y: '-100%',
        scale: 0,
        transition: {
            duration: 0.25
        }
    }
}

// use: Header.tsx
export const fadeInOut = {
    hidden: {
        opacity: 0,
        x: "80vw",
        scale: 0,
    },
    show: {
        opacity: 1,
        x: "0vw",
        scale: 1,
        transition: {
            type: "spring", bounce: 0.25
        }
    },
    exit: {
        opacity: 0,
        x: "-80vw",
        scale: 2,
        transition: {
            duration: 0.5
        }
    }
}

// button scroll top
export const hideRight = {
    hidden: {
        x: 80
    },
    show: {
        x: 0,
        transition: {
            type: 'spring',
        }
    },
    exit: {
        x: 80,
        transition: {
            duration: .3
        }
    }
}

export const forecastAnime = {
    hidden: {
        opacity: 0,
        x: '100%',
    },
    show: {
        opacity: 1,
        x: '0%',
        transition: {
            duration: 1,
            when: "beforeChildren",
            staggerChildren: 0.025,
        }
    },
    exit: {
        opacity: 0,
        x: '100%',
        transition: {
            duration: 1
        }
    }
}

export const animChildren = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            duration: 0.3
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.3
        }
    }
}

// mobile menu 
export const fadeIn2 = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            duration: 0.25,
            when: "beforeChildren",
            staggerChildren: 0.08,
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.5,
            ease: "easeOut",
            then: "afterChildren",
            staggerChildren: 0.1, 
        }
    }
}
export const moveTextRight2 = {
    hidden: {
        opacity: 0,
        x: "20vw",
    },
    show: {
        opacity: 1,
        x: "0%",
        transition: {
            type: "spring",
            mass: 0.5,
        }
    },
    exit: {
        opacity: 0,
        x: "20vw",
    },
    
}
export const noneAnim = {} 

//slider
export const sliderAnimation = {
    hidden: {
        //opacity: 0,
        x: '100%',
    },
    show: {
        opacity: 1,
        x: '0%',
        //zIndex: 0,
        transition: {
            duration: 3,
            //ease: 'linear',
        }
    },
    exit: {
        //opacity: 0,
        x: '-100%',
        //zIndex: -1,
        transition: {
            zIndex: -1,
            duration: 3,
            delay: 3
        }
    }
}

// Animation Members
export const animChildrenMember = {
    hidden: {
        opacity: 0,
        scale: 0.5,
    },
    show: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.5
        }
    },
    exit: {
        opacity: 0,
        scale: 0.5,
        transition: {
            duration: 0.3
        }
    }
}