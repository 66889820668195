

const BASE_URL: string = process.env.REACT_APP_BASE_API as string
const API_KEY: string = process.env.REACT_APP_API_KEY as string

export const api_forecast = `${BASE_URL}data/2.5/onecall?lat=49.105&lon=20.343&units=metric&exclude=minutely,alerts&appid=${API_KEY}`

//'https://api.openweathermap.org/data/2.5/onecall?lat=49.105&lon=20.343&exclude=minutely,alerts&appid=3c92a025d4a82657c8444d3c6bcda105'

// response detail weather for Component InfoLine
export const api_simple_weader = `${BASE_URL}data/2.5/onecall?lat=49.105&lon=20.343&exclude=hourly&appid=${API_KEY}&units=metric&lang=en`

// strapi
export const MY_API_STRAPI: string = 'http://localhost:1337/api/events' as string
export const MY_API_STRAPI_URQL: string = 'http://localhost:1337/graphql' as string

// camera
export const get_image_camera = `http://(ip-adresa)/cgi-bin/api.cgi?cmd=Snap&channel=0&rs=alskdjhfkd54jdkslkdj&user=admin&password=${process.env.REACT_APP_PASSWORD_CAMERA}` 