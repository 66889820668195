import styled from "styled-components";
import { motion } from "framer-motion";
import { mixin } from ".";

export const Line = styled(motion.div)`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: ${mixin.em(2)};
    background: #fff;

    @media (max-width: 700px) {
        background: #1ED760;
    }
`