import { combineReducers } from 'redux'
import openNavReducer from './openNav'
import isLogged from './logged'

const allReducers = combineReducers({
    isOpenNav: openNavReducer,
    isLogged
    
})

export default allReducers