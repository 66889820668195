import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
// style
import styled from 'styled-components'
import { theme } from './Theme'
import { Line } from './Theme/styles'
import { motion, AnimatePresence } from 'framer-motion'
import { showMenuAnim, fadeIn2, moveTextRight2, noneAnim } from '../animations'
import logo from '../assets/image/logo-s.jpg'
import {BiLogOut} from 'react-icons/bi'

// redux
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '..'
import Burger from './Burger'

interface IState {
    subMenu: boolean
}

const Navigation: React.FC = () => {
    const [subMenu, setSubMenu] = useState<IState['subMenu']>(false)
    const {pathname} = useLocation()

    const isOpenNav = useSelector((state: RootState) => state.isOpenNav)
    const isLogged = useSelector((state: RootState) => state.isLogged)
    const dispatch = useDispatch()

    const brakePoint = 700

    // state resize
    function useWindowSize() {
        const [size, setSize] = useState([ window.innerWidth])
        useEffect(() => {
            const handleResize = () => {
                setSize([ window.innerWidth])
            }
            window.addEventListener("resize", handleResize)
            return () => {
              window.removeEventListener("resize", handleResize)
            }
        })
        return size
    }
    const [width] = useWindowSize()

    useEffect(() => {
      if(width <= brakePoint) {
          dispatch({type: 'CLOSE'})
          document.body.style.overflow = 'visible';
      } else {
          dispatch({type: 'OPEN'})
          setSubMenu(false)
      }
    }, [width])

    //TODO toggle show menu
    const showSubmenu = () => {
      setSubMenu(!subMenu)
    }

    // click to link to close mobile navigation
    const handleClick = () => {
        if(width <= brakePoint) {
            dispatch({type: 'CLOSE'})
            setSubMenu(!subMenu)

            // visible body style
            document.body.style.overflow = 'visible';
        }
    }

  return (
    <StyleNavigation>
        <div className="container content">
            <div className="logo">
                <img src={logo} alt="logo" />

                { isLogged && 
                    <motion.div whileHover={{scale: 1.3}}>
                        <Link to="/"
                            onClick={() => {
                                    dispatch({type: 'OUT'}); 
                                    toast.success('Úspešne si sa   odhlásil');
                                    
                                }}>
                            <BiLogOut />
                        </Link> 
                    </motion.div>
                }

            </div>
            {isOpenNav && (
            
                <AnimatePresence>
                <motion.ul variants={fadeIn2} initial="hidden" animate="show" exit="exit" className='menu'>
                    <motion.li variants={width < brakePoint ? moveTextRight2 : noneAnim}
                         key={1} >
                        <Link onClick={handleClick} to="/" >Úvod</Link>
                        <Line 
                            transition={{duration: 0.75}}
                            initial={{width: "0%"}}
                            animate={{width: pathname === "/" ? "100%" : "0%"}}
                        />
                    </motion.li>
                    <motion.li 
                        variants={width < brakePoint ? moveTextRight2 : noneAnim}
                        key={2} 
                        
                        className='sub-menu' 
                        onMouseEnter={showSubmenu}
                        onMouseLeave={showSubmenu}
                        //onTouchStart={showSubmenu}
                        
                    >
                        <a href='#' onClick={(e) => e.preventDefault()} >O nás</a>
                        <div style={{overflow: 'hidden'}}  >
                        <AnimatePresence>
                            {subMenu && (
                                <motion.ul 
                                    className='submenu'
                                    variants={showMenuAnim} 
                                    initial="hidden"
                                    animate="show"
                                    exit="exit"   
                                >
                                    <li>
                                        <Link onClick={handleClick} to="members">Členovia</Link>
                                        <Line 
                                            transition={{duration: 0.75}}
                                            initial={{width: "0%"}}
                                            animate={{width: pathname === "/members" ? "100%" : "0%"}}
                                        />
                                    </li>
                                    <li>
                                        <Link onClick={handleClick} to="airport">Naše letisko</Link>
                                        <Line 
                                            transition={{duration: 0.75}}
                                            initial={{width: "0%"}}
                                            animate={{width: pathname === "/airport" ? "100%" : "0%"}}
                                        />
                                    </li>
                                </motion.ul>

                            )}
                        </AnimatePresence>
                        </div>
                        <Line 
                            transition={{duration: 0.75}}
                            initial={{width: "0%"}}
                            animate={{width: pathname === "/airport" ? "100%" : pathname === "/members" ? "100%" : "0%"}}
                        />
                    </motion.li>
                    <motion.li variants={width < brakePoint ? moveTextRight2 : noneAnim} key={3}  >
                        <Link onClick={handleClick} to="/gallery" >Galeria</Link>
                        <Line 
                            transition={{duration: 0.75}}
                            initial={{width: "0%"}}
                            animate={{width: pathname === "/gallery" ? "100%" : "0%"}}
                        />
                    </motion.li>

                    <motion.li variants={width < brakePoint ? moveTextRight2 : noneAnim} key={4}  >
                        <Link onClick={handleClick} to="/weather" >Počasie</Link>
                        <Line 
                            transition={{duration: 0.75}}
                            initial={{width: "0%"}}
                            animate={{width: pathname === "/weather" ? "100%" : "0%"}}
                        />
                    </motion.li>
                    
                    <motion.li variants={width < brakePoint ? moveTextRight2 : noneAnim} key={5}  >
                        <Link onClick={handleClick} to="/flight_rules" >Letový poriadok</Link>
                        <Line 
                            transition={{duration: 0.75}}
                            initial={{width: "0%"}}
                            animate={{width: pathname === "/flight_rules" ? "100%" : "0%"}}
                        />
                    </motion.li>
                    <motion.li variants={width < brakePoint ? moveTextRight2 : noneAnim} key={6}  >
                        <Link onClick={handleClick} to="/contact" >Kontakt</Link>
                        <Line 
                            transition={{duration: 0.75}}
                            initial={{width: "0%"}}
                            animate={{width: pathname === "/contact" ? "100%" : "0%"}}
                        />
                    </motion.li>
                </motion.ul>
                </AnimatePresence>
            
            
            )}
            <Burger setSubMenu={setSubMenu} />

        </div>
        <Toaster />
    </StyleNavigation>
  )
}
const StyleNavigation = styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    //background: rgba(255, 255, 255, 0.2);
    background: ${theme.backgroundv1};
    z-index: 21;
    border-radius: 0 0 1.5em 0;
    @media (max-width: 650px) {
        background: transparent;
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .logo {
            width: 5em;
            margin-top: 2px;
            display: flex;
            align-items: center;
            img {
                width: 100%;
                height: auto;
            }
            a {
                margin-left: .8em;
                background: rgba(255, 255, 255, 0.5);
                padding: 3px 5px 0 1px;
                border-radius: 3px;
                color: #000;
            }
        }
        
        .menu {
            display: flex;
            position: relative;
            @media (max-width: 700px) {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background: rgba(0, 0, 0, 0.7);
                //backdrop-filter: blur(2px);
                margin-top: 0;

                flex-direction: column;
                align-items: center;
                justify-content: center;
                
            }
            li {
                padding-bottom: .2em;
                margin: 0 1em;
                position: relative;
                
                 a {
                     font-size: 1em;
                     font-weight: 400;
                     //color: #000;
                     color: #fff;
                     transition: ${theme.transition};
                     &:hover {
                         //color: #c3c3c3;
                         color: #1ED760;
                     }
                     
                }
                @media (max-width: 1024px) {
                    margin: 0 .3em; 
                }
                @media (max-width: 700px) {
                    margin: 1em 0;
                }
                @media (max-height: 330px) {
                    margin: 0.7em 0;
                }
                @media (max-height: 300px) {
                    margin: 0.4em 0;
                }
                @media (max-height: 210px) {
                    margin: 0.2em 0;
                }

            }
            .submenu {
                position: absolute;
                top: 1.3em;
                left: -2.7em;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                padding-top: 1em;
                padding-bottom: 1em;

                @media (max-width: 1024px) {
                    left: -2em;
                }
                
                li {
                    width: 6em;
                    background: rgba(0, 0, 0, 0.2);
                    padding: 0.5em 0;
                }
                @media (max-width: 700px) {
                    top: -3em;
                    left: 2em;
                    padding-left: 2em;
                    li {
                        margin: 0.5em;
                    }
                }
            }
            
        }
    }
`


export default Navigation