import React, {useEffect} from 'react'
import styled from 'styled-components'


interface IProps {
    setOpenWind: (value: boolean) => void
}

const AutoClose: React.FC<IProps> = ({setOpenWind}) => {

    useEffect(() => {
      setTimeout(() => {
        setOpenWind(false)
      }, 5000);
    }, [])

  return (
    <StyleLoadind className="flex-center">
        <span>Loading...</span>
    </StyleLoadind>
  )
}
const StyleLoadind = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        padding: 0.8em 1em;
        background: rgba(255,255,255,0.9);
        border-radius: 0.3em;
        z-index: 10;
    }
`

export default AutoClose