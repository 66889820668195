import { weekdays } from "moment"

/**
 * format time hh:mm AM/PM
 * @param {UTC} sec 
 * @returns string
 */
 export const sunn = (sec: number): string => {
    const date = new Date(sec * 1000)
    const timestr = date.toLocaleTimeString(['en-US'], { hour: '2-digit', minute: '2-digit' })
    return timestr
}


 export const hour = (sec: number): number => {
    const date = new Date(sec * 1000)
    const timestr = date.getHours()
    return timestr
}

 export const dayString = (sec: number): string => {
    const options: any = {
        weekday: 'long'
    }
    const date = new Date(sec * 1000)
    const timestr = date.toLocaleTimeString(undefined, options)
    return timestr
}

// vráti deň 2-znaky po slovensky
// return day short sk
export const dayShortString = (sec: number): string => {
    const date = new Date(sec * 1000)
    const timestr = date.toLocaleDateString(['sk'], {  weekday: 'short', day: '2-digit' })
    const array = timestr.split(" ")
    const newString = `${array[0]}`
    return newString
}