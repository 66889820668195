import React from 'react'
import styled from 'styled-components'

interface IState {
    img: string;
    partner: string;
    href: string;
    clas?: string;
    bossclas?: string;
}

const Partner: React.FC<IState> = ({img, partner, href, clas, bossclas}) => {
  return (
    <StylePartner className={bossclas}>
        <p>Významný partner:</p>
        <h3>{partner}</h3>
        <a href={href} target='_blank' rel="noreferrer" >
        <img className={clas} src={img} alt="icon" /></a>
    </StylePartner>
  )
}

const StylePartner = styled.div`
    display: flex;
    flex-direction: column;

    img {
        width: 5em;
        height: auto;
    }
`

export default Partner