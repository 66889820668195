import { useEffect, useState, lazy, Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
//import moment from 'moment';
import { dayShortString, hour } from './units';
// Api
import axios from 'axios';
import { api_forecast, MY_API_STRAPI_URQL } from './api';
import { Provider, createClient } from 'urql';
// Components
import Header from './components/Header';
import InfoLine from './components/InfoLine';
import Home from './pages/Home';
//import Airport from './pages/Airport';
//import Members from './pages/Members';
//import Gallery from './pages/Gallery';
//import FlightRules from './pages/FlightRules';
//import Contact from './pages/Contact';
//import Weather from './pages/Weather';
//import OneYearGallery from './pages/OneYearGallery';
import Footer from './components/Footer';
// Style
import './App.css';
// Animation
import { AnimatePresence } from 'framer-motion'
import ScrollTopButton from './components/Scroll/ScrollTopButton';
// Parallax
import { ParallaxProvider } from 'react-scroll-parallax';
import Login from './components/login/Login';

// lazy
const Airport = lazy(() => import('./pages/Airport'))
const Members = lazy(() => import('./pages/Members'))
const Gallery = lazy(() => import('./pages/Gallery'))
const FlightRules = lazy(() => import('./pages/FlightRules'))
const Contact = lazy(() => import('./pages/Contact'))
const Weather = lazy(() => import('./pages/Weather'))
const OneYearGallery = lazy(() => import('./pages/OneYearGallery'))

// get data strapi graphql
const client = createClient({url: MY_API_STRAPI_URQL})

export interface ForecastHour {
  temp: number;
  wind: number;
  hour: number;
  dt?: number;
  day: string;
}

interface IState {
  showButtonTop: boolean;
  forecastHour: ForecastHour[] | []
  timeGetForecast: number
}

function App() {
  const [showButtonTop, setShowButtonTop] = useState<IState['showButtonTop']>(false)
  const [forecastHour, setForecastHour] = useState<IState['forecastHour']>([])
  const [timeGetForecast, setTimeGetForecast] = useState<IState['timeGetForecast']>(0)
  const location = useLocation()

  useEffect(() => {
    getForecast()

    // console.log(sunn(1649919600));
    // console.log(dayString(1650013200))
  }, [])

  // show/hidden to butto to top
  useScrollPosition(({currPos}) => {
    if(-currPos.y > 300) {
        setShowButtonTop(true)
    } else {
        setShowButtonTop(false)
    }
  })

  // get foracast
  const getForecast = async () => {
    await axios.get(
      api_forecast
    )
    .then(data => {
      //console.log(data.data.hourly);
      const currentData = data.data.hourly
      const filterData = currentData.map((item: any) => ({
        temp: item.temp,
        wind: item.wind_speed,
        hour: hour(item.dt),
        dt: item.dt,
        day: dayShortString(item.dt)
      })
        
      )
      setForecastHour(filterData)
      setTimeGetForecast(Math.round(new Date().getTime() / 1000))
      console.log('načitalo nové data');
      
      
    }).catch(err => console.log(err))
  }

  return (
    <Provider value={client} >
    <ParallaxProvider>
    <div className="App">
      <Header forecast={forecastHour} currentGet={getForecast}/>

      <InfoLine />

      <AnimatePresence exitBeforeEnter>
        <Suspense fallback={ <article><h3>Loading...</h3></article> }>
        <Routes location={location} key={location.pathname} >
          <Route path='/' element={ <Home /> } />
          <Route path='/login' element={ <Login /> } />

          <Route path='/airport' element={ <Airport /> } />
          <Route path='/members' element={ <Members /> } />

          <Route path='/gallery' element={ <Gallery /> } />
          <Route path='/gallery/:year' element={ <OneYearGallery path={location.pathname} /> } />
          
          <Route path='/weather' element={ <Weather forecast={forecastHour} currentGet={getForecast} time={timeGetForecast} /> } />
          <Route path='/flight_rules' element={ <FlightRules /> } />

          <Route path='/contact' element={ <Contact /> } />
        </Routes>
        </Suspense>

      </AnimatePresence>

      <AnimatePresence>
        {showButtonTop && <ScrollTopButton />}
      </AnimatePresence>

      <Footer />
    </div>
    </ParallaxProvider>
    </Provider>
  );
}

export default App;
