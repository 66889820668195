import { ActionType } from "./openNav"

interface actionIn {
    type: ActionType.IN
}
interface actionOut {
    type: ActionType.OUT
}

type ActionLogged = actionIn | actionOut

const isLogged = (state: boolean = false, action: ActionLogged) => {
    switch(action.type) {
        case ActionType.IN:
            return true;
        case ActionType.OUT:
            return false;
            default:
                return state
    }
}
export default isLogged