import React from 'react'
import AliceCarousel from 'react-alice-carousel'
// style
import styled from 'styled-components'
import 'react-alice-carousel/lib/alice-carousel.css'
// images 
import back1 from '../assets/image/background/back-top-01.jpg'
import back2 from '../assets/image/background/back-top-02.jpg'
import back3 from '../assets/image/background/back-top-2.jpg'
import back4 from '../assets/image/background/back-top-6000-2.JPG'
import back5 from '../assets/image/background/back-top-05.jpg'
import back6 from '../assets/image/background/back-top-6.JPG'
import back7 from '../assets/image/background/back-top-7.JPG'

import back1s from '../assets/image/background/small/back-1-s.webp'
import back2s from '../assets/image/background/small/back-2-s.webp'
import back3s from '../assets/image/background/small/back-3-s.webp'
import back4s from '../assets/image/background/small/back-4-s.webp'
import back5s from '../assets/image/background/small/back-5-s.jpg'
import back6s from '../assets/image/background/small/back-6-s.webp'
import back7s from '../assets/image/background/small/back-7-s.jpg'
 
import { useParallax } from 'react-scroll-parallax'

// poradie obrázkov pre pozadie hlavičky
// order picture for background header
const SliderAliceCarousel: React.FC = () => {
    const sliderImage = [
        {large: back4, small: back4s},
        {large: back5, small: back5s},
        {large: back6, small: back6s},
        {large: back1, small: back1s},
        {large: back2, small: back2s},
        {large: back3, small: back3s},
        {large: back7, small: back7s},
    ]

    const { ref } = useParallax<HTMLDivElement>({ speed: 0 })

  return (
    <StyleSlider className='ali' ref={ref} >

        <AliceCarousel 
            autoPlay={true}
            animationDuration={3000}
            autoPlayInterval={10000} 
            infinite={true}
            disableSlideInfo={false}
            animationType={'fadeout'}
            // autoWidth={true}
            // autoHeight={true}
            disableDotsControls={true}
            disableButtonsControls={true}
            keyboardNavigation={true}

        >
            {sliderImage.map((item, index) => (
                <picture key={index}>
                    <source 
                        media='(max-width: 800px)' 
                        srcSet={item.small}
                    />
                    <img 
                        src={item.large} 
                        alt={`image${index}`} 
                        className="image-slider"
                    />
                </picture>
            ))}
        </AliceCarousel>

    </StyleSlider>
  )
}
const StyleSlider = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    .alice-carousel {
        width: 100%;
        height: 100%;
        div {
            width: 100%;
            height: 100%;
        }
    }
    .alice-carousel__slide-info {
        background: transparent;
    }
    .alice-carousel__slide-info-item {
        color: transparent;
    } 
    .image-slider {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

export default SliderAliceCarousel