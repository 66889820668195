import React, {useState} from 'react'
import styled from 'styled-components'
import toast, {Toaster} from 'react-hot-toast'
import { motion } from 'framer-motion'
import { pageAnimation } from '../../animations'
import { useNavigate } from 'react-router-dom'

// redux
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../..'

// link odkial som čerpal kod formulara
// https://contactmentor.com/login-form-react-js-code/

interface IState {
    errorMessages: {
        name: string,
        message: string
    }  | {}
    isSubmitted: boolean
}
interface IError {
    uname: string;
    pass: string;
} 

const Login: React.FC = () => {

  // React States
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState<IState['isSubmitted']>(false);

  const isLogged = useSelector((state: RootState) => state.isLogged)
  const dispatch = useDispatch()

  const navigate = useNavigate()


  // User Login info
  const database = [
    {
      username: "user1",
      password: `${process.env.REACT_APP_USER1}`
    },
    {
      username: "user2",
      password: `${process.env.REACT_APP_USER2}`
    }
  ];

  const errors: IError = {
    uname: "invalid username",
    pass: "invalid password"
  };

  
  const handleSubmit = (event: any) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];
    

    // Find user login info
    const userData = database.find((user) => user.username === uname.value.trim());

    // Compare user info
    if (userData) {
      if (userData.password !== pass.value) {
        // Invalid password
        setErrorMessages({ name: "pass", message: errors.pass });
        toast.error(userData.username + 'zadal si zlé heslo !!!')

      } else {
        setIsSubmitted(true);
        dispatch({type: 'IN'})
        toast.success('Prihlásenie bolo úspešné',{duration: 3000})
        navigate('/gallery')
      }
    } else {
      // Username not found
      setErrorMessages({ name: "uname", message: errors.uname });
      toast.error('Zlé meno')
    }
  };

  // odhlasenie v redux state
  const handleLogout = () => {
    dispatch({type: 'OUT'})
    toast.success('Úspešne si sa odhlásil')
  }



  // JSX code for login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Meno </label>
          <input type="text" name="uname" required />
        </div>
        <div className="input-container">
          <label>Heslo </label>
          <input type="password" name="pass" required />
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );

  // JSX code for logOut
  const renderLogout = (
    <div>
        <button onClick={handleLogout} className='log-out'>Odhlasiť</button>
    </div>
  )

  return (
    <StyleLogin 
        variants={pageAnimation}
        initial="hidden"
        animate="show"
        exit="exit"
    >
        <div className="login-form">
            <div className="title">Prihlásenie</div>
            {isLogged ? 
                <div>
                    <p>Používateľ je úspešne prihlásený</p>
                    {renderLogout}
                </div> :
                 renderForm}
        </div>
        <Toaster />
    </StyleLogin>
  )
}
const StyleLogin = styled(motion.div)`
    font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 60vh;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  background-color: #f8f9fd;

  input[type="text"],
input[type="password"] {
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

input[type="submit"], .log-out {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #01d28e;
  border: 1px solid #01d28e;
  color: #fff;
  padding: 10px 20px;
}

input[type="submit"]:hover,
.log-out:hover {
  background: #6cf0c2;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}
`

export default Login
