import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '..'

interface IProps {
    setSubMenu: (subMenu: boolean) => void
}

const Burger: React.FC<IProps> = ({ setSubMenu }) => {
    const dispatch = useDispatch()
    const isOpenNav = useSelector((state: RootState) => state.isOpenNav)
    
    const handleClick = () => {
      dispatch({type: 'TOGGLE_NAV' })
      setSubMenu(false)

      // if open mobile navigation don't scroll body 
      const curretOverflowBody = document.body.style.overflow 
      document.body.style.overflow = curretOverflowBody === 'hidden' ? 'visible' : 'hidden';
    }
    
  return (
    <StyleBurger
        onClick={handleClick}
    >
        <Line1 className={isOpenNav ? "l1" : ""}/>
        <Line2 className={isOpenNav ? "l2" : ""}/>
        <Line3 className={isOpenNav ? "l3" : ""}/>
    </StyleBurger>
  )
}
const StyleBurger = styled.div`
    width: 1.4em; 
    height: 1.2em;
    position: absolute;
    top: 1em;
    right: 2em;
    cursor: pointer;
    transition: 600ms all ease;
    display: none;

    @media screen and (max-width: 700px) {
        display: block;
        z-index: 40;
    }
    @media (max-width: 500px) {
        top: 1em;
    }
    .l1 {
    transform: rotate(45deg) translate(26%, 250%);
    }
    .l2 {
        transform: translateY(-50%) translateX(100%);
        opacity: 0;
    }
    .l3 {
        transform: rotate(-45deg) translate(26%, -250%);
    }
`
const Line = styled(motion.div)`
    width: 1.4em;
    height: 0.15em;
    background: white;
    position: absolute;
    transition: 500ms all ease;

    @media (max-width: 650px) {
        //background: #000;
        //box-shadow: 0px 0px 6px 1px #fff;
    }
`
const Line1 = styled(Line)`
    top: 0;
    transform: rotate(0deg) translate(0%, 0%);
    
`
const Line2 = styled(Line)`
    top: 50%;
    transform: translateY(-50%);
`
const Line3 = styled(Line)`
    bottom: 0;
    transform: rotate(0deg) translate(0%, 0%);
`

export default Burger