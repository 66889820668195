import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
// components
import TestWind from './forecast/TestWind'
// style
import styled from 'styled-components'
import Navigation from './Navigation'
import { mixin } from './Theme'
// images
import back1 from '../assets/image/background/back-top-01.jpg'
import {FiRefreshCcw} from 'react-icons/fi'

import mini_arrow from '../assets/image/miniArrow.svg'
// Animation
import { AnimatePresence, motion } from 'framer-motion'
import { fadeInOut, forecastAnime } from '../animations'
import { ForecastHour } from '../App'

//import { hour } from '../units'
import SliderAliceCarousel from './SliderAliceCarousel'

import { useParallax } from 'react-scroll-parallax'

 interface IProps {
     forecast: ForecastHour[] | null
     currentGet: () => void
 }

const Header: React.FC<IProps> = ({ forecast, currentGet }) => {
    const location = useLocation()
    const [locat, setLocat] = useState(location.pathname)
    const [showTitle, setShowTitle] = useState(true)
    const [openWind, setOpenWind] = useState(false)
    
    useEffect(() => {
        setShowTitle(false)
        setTimeout(() => {
            setLocat(location.pathname)
            setShowTitle(true)
        }, 1000);
    }, [location])

    // Parallax effect
    

    const changeLocation = (path: string) => {
        switch(path) {
            case '/':
                return 'LMK Veľká Lomnica';
            case '/airport':
                return 'Naše letisko';
            case '/members':
                return 'Členovia';
            case '/gallery':
                return 'Galéria';
            case '/gallery/2017':
                return '2017';
            case '/gallery/2018':
                return '2018';
            case '/gallery/2019':
                return '2019';
            case '/gallery/2021':
                return '2021';
            case '/gallery/2022':
                return '2022';
            case '/gallery/2023':
                return '2023';
            case '/weather':
                return 'Počasie';
            case '/flight_rules':
                return 'Letový poriadok';
            case '/contact':
                return 'Kontakt';
            case '/login':
                return 'Prihlásenie';
            default: 
                return ''
        }
    }

    const handleToggle = () => {
        setOpenWind(!openWind)
        //const hourNow = hour(+(new Date().getTime() / 1000).toFixed(0))
        //console.log(hourNow);
    }

    const refreshForecast = () => {
      currentGet()
      console.log("načitalo nove počasie klikom na refresh icon");
    }

    //const { ref } = useParallax({ speed: 5 })
    
  return (
    <StyleHeader className='header-anim' >
        <div >

        <SliderAliceCarousel />
        </div>

        <div className="content">
            <Navigation />
            <AnimatePresence>
            {showTitle && (
                <motion.div 
                    variants={fadeInOut} initial="hidden" 
                    animate="show" exit="exit" 
                    className="title"
                >
                    <h2 style={{bottom: openWind ? '1.3em' : '0em'}}>{changeLocation(locat)}</h2>
                </motion.div>
            )}
            </AnimatePresence>
        </div>
        
        {openWind && (
            <motion.div 
                className='refrech-forecast'
                initial={{opacity: 0}}
                animate={{opacity: 1, transition: {delay: 1}}}
                onClick={refreshForecast}
            >
                <FiRefreshCcw />
            </motion.div>
        )}

        <div className="open-forecast" onClick={handleToggle}>
            <button 
                //style={{borderRadius: openWind ? '1px 13px 13px 1px' : '13px 1px 1px 13px'}} 
            >
                { !openWind && <img src={mini_arrow} alt="arrow" className='arrow-show' /> }
                <img src={mini_arrow} alt="arrow" className='mobile-arrow'
                    style={{transform: openWind ? 'rotate(90deg)' : 'rotate(-90deg)'}}
                />
                Vietor
                { openWind && <img src={mini_arrow} alt="arrow" className='arrow-fade' /> }
            
            </button>
                {/* <img src={wind_img} alt="wind-icon" /> */}
        </div>

        <AnimatePresence>
            {openWind && <TestWind setOpenWind={setOpenWind}  forecast={forecast} /> }
        </AnimatePresence>

    </StyleHeader>
  )
}
const StyleHeader = styled.header`
    width: 100%;
    min-height: ${mixin.em(420)};
    background: #eee;
    position: relative;
    //background-image: url(${back1});
    background-position: center;
    background-size: cover;
    overflow: hidden;
    @media (max-width: 1024px) {
        min-height: ${mixin.em(330)};
    }
    @media (max-width: 650px) {
        min-height: ${mixin.em(230)};
    }

    .open-forecast {
        position: absolute;
        bottom: 8.5em;
        right: .5em;
        //background: #fff;
        //padding: 0.3em;
        border-radius: 2%;
        cursor: pointer;
        z-index: 20;
        @media (max-width: 500px) {
                transform: rotate(-90deg);
                bottom: 9em;
                right: -1.2em;
            }
        button {
            cursor: pointer;
            padding: 0.1em .6em;
            border: none;
            border-radius: 3px;
            transition: 300ms all ease-in-out;
            background: rgba(255, 255, 255, 0.5);

            @media (max-width: 500px) {
                font-size: .8em;
                padding: 0.1em .6em;
            }

            .arrow-fade {
                height: 0.7em;
                margin-left: 3px;
                @media (max-width: 500px) {
                display: none;
                }
            }
            .arrow-show {
                height: 0.7em;
                margin-right: .3em;
                transform: rotate(180deg);
                @media (max-width: 500px) {
                display: none;
                }
            }
            .mobile-arrow {
                display: none;
                @media (max-width: 500px) {
                display: inline;
                height: 0.7em;
                margin-right: .3em;
                }
            }
        }
        
    }

    .refrech-forecast {
        position: absolute;
        bottom: 6.7em;
        right: 2em;
        //background: #fff;
        //padding: 0.3em;
        border-radius: 2%;
        cursor: pointer;
        z-index: 20;

        @media (max-width: 830px) {
            bottom: 6.2em;
            right: 2em;
        }
    }

    .content {
        max-width: ${mixin.em(1200)};

    }

    .title {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transform-origin: center center;
        @media (max-width: 650px) {
            //align-items: flex-start;
            //margin-top: 2.5em;
        }
        h2 {
            font-weight: bold;
            padding: .5em 1em;
            letter-spacing: ${mixin.em(1)};
            background: rgba(255, 255, 255, 0.1);
            //background: rgba(0, 0, 0, 0.3);
            //color: #fff;
            border-radius: .2em;  
            box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
            text-shadow: 1px 0px #fff;
            @media (max-width: 650px) {
                padding: 0.3em .8em;
                position: relative;
                transition: 300ms all ease;
                transition-delay: 300ms;
                //bottom: 1.3em;
            }
        }
    }
`

export default Header