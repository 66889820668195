import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { api_simple_weader } from '../api'
import styled from 'styled-components'
// Icons
import {GiSunset, GiSunrise} from 'react-icons/gi'
// Types
import { DataForLine } from '../types'
// units
import { sunn } from '../units'
import moment from 'moment'
moment().locale('sk')


interface IState {
    simpleInfo: DataForLine | null
}

const InfoLine: React.FC = () => {
    const [simpleInfo, setSipleLine] = useState<IState['simpleInfo']>(null)
    
    useEffect(() => {
        if(simpleInfo === null) getData() 
        //getData() 
    }, [])
    
    const getData = async () => {
        await axios.get(api_simple_weader)
        .then(data => {
            //console.log(data.data);
            
            const NewData: DataForLine = {
                sunSet: data.data.current.sunset,
                sunRise: data.data.current.sunrise,
            }
            setSipleLine(NewData)
            //console.log(NewData);
            
        })
    }
    
    moment().locale('sk')
    const now = moment().format('D-M-YYYY')
    

    if(simpleInfo === null) {
        return (
            <InfoStyle>{moment().format('llll')}</InfoStyle>
        )
    }

  return (
    <InfoStyle>
        <p>
            {now}
            <GiSunrise />{sunn(simpleInfo.sunRise)}
            <GiSunset />{sunn(simpleInfo.sunSet)}
        </p>
    </InfoStyle>
  )
}
const InfoStyle = styled.div`
    text-align: left;
    padding: 0 1rem;
    p {
        margin: .4rem 0;
    }
    //border-bottom: 1px solid black;
    svg {
        margin-left: .8rem;
    }
`

export default InfoLine