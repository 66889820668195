import React from 'react';
import ReactDOM from 'react-dom/client';
import 'normalize.css'
import './index.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { HashRouter } from 'react-router-dom'
// redux
import allRecucers from './reducers'
import { createStore } from 'redux'
import { Provider } from 'react-redux'

const store = createStore(
  allRecucers
)



export type RootState = ReturnType<typeof store.getState>

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </React.StrictMode>
);


